<template>
  <div class="absolute-custom-dropdown"
       v-click-outside="outside"
       :style="{height}"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, 'direction-' + direction, {'global-block': dropdownInGlobalBlock}, classes]">
    <template v-if="triggerInSlot">
      <div class="select-styled"
           :class="{'filter-applied': filterApplied}"
           :style="{minWidth: minWidth, height}"
           ref="optionsBlock"
           @click="selectDropdownClicked">
      <span id="selected-value">
        <slot name="header"></slot>
      </span>
        <template v-if="withIcon">
          <div v-if="!filterApplied"
               class="icon">
            <svg-icon icon="angle-down-solid"/>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="select-styled"
           :class="{'filter-applied': filterApplied}"
           :style="{minWidth: minWidth, height}"
           ref="optionsBlock"
           @click="selectDropdownClicked">
      <span id="selected-value">
        <slot name="header"></slot>
      </span>
        <template v-if="withIcon">
          <div v-if="!filterApplied"
               class="icon">
            <svg-icon icon="angle-down-solid"/>
          </div>
        </template>
      </div>
    </template>

    <template v-if="useTeleport">
      <teleport to="#app .wrapper">
        <div class="absolute-select-options-wrap"
             v-if="isOpened"
             :style="[{top: tooltipVerticalPosition?.top}, tooltipHorizontalPosition, {width: width + 'px'}]"
             :class="[{active: isOpened}, tooltipVerticalPosition?.cssClass]"
             ref="optionsWrap">
          <slot name="content"></slot>
        </div>
      </teleport>
    </template>
    <template v-else>
      <div class="absolute-select-options-wrap"
           v-if="isOpened"
           :style="[{top: tooltipVerticalPosition?.top}, tooltipHorizontalPosition, {width: width + 'px'}]"
           :class="[{active: isOpened}, tooltipVerticalPosition?.cssClass]"
           ref="optionsWrap">
        <slot name="content"></slot>
      </div>
    </template>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "CustomDropdownAbsolute",
  props: {
    useTeleport: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object
    },
    classes: {
      type: String
    },
    initialData: {
      type: String
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    },
    dropdownAlign: {
      type: String,
      default: 'left'
    },
    minWidth: {
      type: String
    },
    dropdownWidth: {
      type: String
    },
    dataUpdated: {
      required: false
    },
    dropdownInGlobalBlock: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: true
    },
    withShadow: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    closeDropdown: {
      type: Number,
      required: false
    },
    straight: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      required: false
    },
    elWidth: {
      type: String,
      required: false
    },
    triggerInSlot: {
      type: Boolean,
      default: false
    },
    asidePositioning: {
      type: Boolean,
      default: false
    },
  },
  emits: ['filter-applied', 'dropdown-closed', 'dropdown-opened', 'dropdown-clicked-outside'],
  data() {
    return {
      isOpened: false,
      appliedPreset: {},
      checkedRadio: null,
      presetsInnerData: {},
      filterApplied: false,
      showTooltipContent: false,
      directVertical: null,
      directHorizontal: null,
      boundingRect: {},
      maxHeight: '280px',
      width: 150,
      componentId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 10),
    }
  },
  methods: {
    outside(event) {
      const parentTable = event?.target?.closest('table');
      if (!parentTable?.closest('div')?.classList?.contains('uncovered-tooltip-table')) {
        this.isOpened = false;
        this.$emit('dropdown-clicked-outside');
        this.boundingRect = {};
      }
    },
    selectDropdownClicked(e) {
      this.$store.dispatch('SET_ABSOLUTE_DROPDOWN_ID', this.componentId);

      if (e?.target?.classList?.contains('icon') || e?.target?.classList?.contains('applied-icon') || e?.target?.closest('.svg-icons')?.classList?.contains('applied-icon')) {
        if (this.isOpened) {
          this.isOpened = true;
        }
      } else {
        this.isOpened = !this.isOpened;
        if (this.isOpened) {
          this.$nextTick(() => {
            this.$emit('dropdown-opened', this.$refs.optionsWrap);
          });
        } else {
          this.$nextTick(() => {
            this.$emit('dropdown-closed', this.$refs.optionsWrap);
          });
        }
      }

      if (this.isOpened && this.useTeleport) {
        this.tooltipMouseOver(e);
      }
    },
    tooltipMouseOver(e) {
      this.showTooltipContent = true;
      e.stopPropagation();

      setTimeout(() => {
        const rect = this.$refs.optionsBlock?.getBoundingClientRect();
        let tempRect = {};
        tempRect.top = rect.top + window.scrollY;
        tempRect.bottom = rect.bottom + window.scrollY;
        tempRect.left = rect.left;
        tempRect.right = rect.right;
        tempRect.height = this.$refs.optionsWrap?.clientHeight === null || this.$refs.optionsWrap?.clientHeight === 0
         ? this.$refs.optionsWrap?.clientHeight
         : parseInt(this.maxHeight);

        this.boundingRect = {...tempRect};
      }, 50);

      this.$nextTick(() => {
        const tooltipContent = this.$refs.optionsWrap;
        if (tooltipContent) {
          this.boundingRect.height = tooltipContent.clientHeight;
          this.boundingRect.width = tooltipContent.clientWidth;
        }
      })
    },
    tooltipMouseLeave() {
      this.showTooltipContent = false;
    },
    tooltipContentMouseOver() {
      if (this.delayedClosing) {
        this.showTooltipContent = true;
      }
    },
    tooltipContentMouseLeave() {
      this.showTooltipContent = false;
    }
  },
  mounted() {
    if (this.elWidth) {
      this.width = this.elWidth;
    } else {
      this.width = this.$refs.optionsBlock?.clientWidth;
    }
  },
  computed: {
    ...mapGetters([
      'absoluteDropdownOpenedId',
    ]),
    tooltipVerticalPosition() {
      let cssClass = 'to-bottom';
      let positionTop = '';
      if (this.directVertical === 'top') {
        positionTop = `calc(${this.boundingRect.top}px - 4px - ${this.boundingRect.height ?? 0}px)`;
        return {
          top: positionTop,
          cssClass,
        }

        // return `top: calc(${this.boundingRect.top}px - 4px - ${this.boundingRect.height ?? 0}px)`;
      } else {
        let positionTop = '';
        if ((this.boundingRect.top < parseInt(this.maxHeight)) && (window.innerHeight - this.boundingRect.top) < parseInt(this.maxHeight)) {
          positionTop = `calc(${this.boundingRect.top}px - 4px - ${this.boundingRect.height / 2 ?? 0}px)`;
        } else if ((window.innerHeight - this.boundingRect.top) <= parseInt(this.maxHeight)) {
          let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
          let bottom = this.boundingRect.bottom - scrollTop;

          if ((window.innerHeight - bottom) > this.boundingRect.height) {
            if (this.asidePositioning) {
              positionTop = `calc(${this.boundingRect.top}px - 0px`;
            } else {
              positionTop = `calc(${this.boundingRect.bottom}px + 1px)`;
            }

          } else {
            positionTop = `calc(${this.boundingRect.top}px - ${this.$refs.optionsWrap?.clientHeight}px - 2px`;
            cssClass = 'to-top';
          }

        } else {
          positionTop = `calc(${this.boundingRect.top}px - 4px)`;
          cssClass = 'to-bottom';
        }

        return {
          top: positionTop,
          cssClass,
        }
        // return `top: ${positionTop}`;
      }
    },
    tooltipHorizontalPosition() {
      let left = this.$refs?.optionsBlock?.getBoundingClientRect()?.x;
      if (this.asidePositioning) {
        left += this.$refs.optionsBlock?.clientWidth + 17;
        return `left: calc(${left}px);`;
      } else {
        return `left: calc(${left}px);`;
      }
    },
  },
  watch: {
    dataUpdated: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.isOpened = false;
        }
      },
      deep: true
    },
    closeDropdown(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        if (this.absoluteDropdownOpenedId !== this.componentId) {
          this.isOpened = false;
        }
      }
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>