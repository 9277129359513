<template>
  <div class="metadata-table-layout">
    <table class="metadata-custom-table"
           :class="[{edit: tableMode === 'edit', view: tableMode === 'view'}, classes]">

      <tbody class="tbody">
      <template v-for="(row, key) in computedMetaData">
        <tr class="trow locale-row">
          <td class="td-item locale-name" colspan="3">{{ row?.locale_name }}</td>
        </tr>
        <tr class="trow middle-row">
          <td class="medium">
            <editable-table-cell :table-mode="tableMode"
                                 :initial-data="row?.title?.text"
                                 :store-data="storeData?.metas[key]?.title?.text"
                                 :duplicated-words="row?.duplicated"
                                 :text-max-length="30"
                                 view-mode-placeholder="No title"
                                 edit-mode-placeholder="Enter the title"
                                 @turn-on-edit-mode="turnOnEditMode"
                                 @editable-data-changed="editableDataChanged(key, 'title', $event)">
              <template v-slot:cell-title>Title</template>
            </editable-table-cell>
          </td>
          <td class="no-border medium">
            <editable-table-cell :table-mode="tableMode"
                                 :initial-data="row?.sub_title?.text"
                                 :store-data="storeData?.metas[key]?.sub_title?.text"
                                 :duplicated-words="row?.duplicated"
                                 :text-max-length="30"
                                 view-mode-placeholder="No subtitle"
                                 edit-mode-placeholder="Enter the subtitle"
                                 @turn-on-edit-mode="turnOnEditMode"
                                 @editable-data-changed="editableDataChanged(key, 'sub_title', $event)">
              <template v-slot:cell-title>Subtitle</template>
            </editable-table-cell>
          </td>
          <td rowspan="2" class="border-left medium align-top words-block">
            <editable-table-cell :table-mode="tableMode"
                                 :cell-type="'keyword-cell'"
                                 :text-max-length="100"
                                 view-mode-placeholder="No keywords"
                                 edit-mode-placeholder="Enter keywords"
                                 :duplicated-words="row?.duplicated"
                                 :initial-data="null"
                                 :initial-list-data="row?.keywords"
                                 :store-data="storeData?.metas[key]?.keywords.join(',')"
                                 @turn-on-edit-mode="turnOnEditMode"
                                 @editable-data-changed="editableDataChanged(key, 'keywords', $event)">
              <template v-slot:storeDataAfter>
                <custom-tooltip :style="{minWidth: '30px'}"
                                class="store-keywords-tooltip"
                                @click="showStoreKeywordsModal">
                  <template v-slot:slotTrigger>
                    <svg-icon icon="pencil-icon"/>
                  </template>
                  <template v-slot:slotContent>
                    Edit Store Keywords
                  </template>
                </custom-tooltip>
              </template>
              <template v-slot:cell-title>Keywords</template>
              <template v-slot:storeDataPlaceholder>
                <div class="no-title-block">
                  The system doesn't allow us to get Keywords from AppStore. Please add keywords manually to avoid duplicates.
                </div>
                <div class="enter-keywords-fields">
                  <img src="@/assets/images/icons/app_store_gray.svg"
                       alt="Store icon"
                       class="store-icon"
                       width="18"
                       height="18">
                  <span @click="showStoreKeywordsModal" class="link">Enter keywords fields</span> data from App Store Console
                </div>
              </template>
            </editable-table-cell>
          </td>
        </tr>
        <tr class="trow large-row">
          <td class="medium">
            <editable-table-cell :table-mode="tableMode"
                                 classes="words-to-use-editable"
                                 :cell-type="'keyword-cell'"
                                 :show-length-limits="false"
                                 :text-max-length="10000"
                                 view-mode-placeholder="You can add words from the table below (see Words tab)."
                                 edit-mode-placeholder="Enter words"
                                 :initial-data="null"
                                 :initial-list-data="wordsToUseProcessed(row?.words_to_use_in_title, row?.title?.used_words)"
                                 :duplicated-words="row?.duplicated"
                                 :no-highlight="true"
                                 :words-to-use="row?.words_to_use_in_title"
                                 :used-words-data="row?.title.used_words"
                                 @turn-on-edit-mode="turnOnEditMode"
                                 @editable-data-changed="editableDataChanged(key, 'words_to_use_in_title', $event)">
              <template v-slot:cell-title>Words to use</template>
            </editable-table-cell>
          </td>
          <td class="no-border medium">
            <editable-table-cell :table-mode="tableMode"
                                 classes="words-to-use-editable"
                                 :cell-type="'keyword-cell'"
                                 :show-length-limits="false"
                                 :text-max-length="10000"
                                 view-mode-placeholder="You can add words from the table below (see Words tab)."
                                 edit-mode-placeholder="Enter words"
                                 :initial-data="null"
                                 :initial-list-data="wordsToUseProcessed(row?.words_to_use_in_subtitle, row?.sub_title?.used_words)"
                                 :duplicated-words="row?.duplicated"
                                 :no-highlight="true"
                                 :used-words-data="row?.sub_title.used_words"
                                 @turn-on-edit-mode="turnOnEditMode"
                                 @editable-data-changed="editableDataChanged(key, 'words_to_use_in_subtitle', $event)">
              <template v-slot:cell-title>Words to use</template>
            </editable-table-cell>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import EditableTableCell from "@/views/MetaEditor/components/EditableTableCell/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: "MetaEditorTable",
  components: {
    SvgIcon,
    EditableTableCell,
  },
  emits: [
    'editable-table-value-changed',
    'turn-on-edit-mode',
    'show-store-keywords-modal',
  ],
  props: {
    selectedLocales: {
      type: Array,
      default: [],
    },
    tableMode: {
      type: String,
      default: 'view'
    },
    tableData: {
      type: Object,
    },
    storeData: {
      type: Object,
    },
    classes: {
      type: String,
    },
  },
  data() {
    return {
      searchInput: '',
      localTableData: {},
    }
  },
  mounted() {
    this.localTableData = {...this.tableData};
  },
  methods: {
    showStoreKeywordsModal() {
      this.$emit('show-store-keywords-modal');
    },
    editableDataChanged(localeCode, itemType, newValue) {
      this.$emit('editable-table-value-changed', {
        localeCode,
        itemType,
        newValue
      });
    },
    turnOnEditMode() {
      this.$emit('turn-on-edit-mode');
    },
    wordsToUseProcessed(wordsToUse, usedWordsData) {
      let usedWordsDataLowerMap = {};
      usedWordsData?.forEach(item => {
        usedWordsDataLowerMap[item.toLowerCase()] = item;
      });

      wordsToUse = [...wordsToUse];

      return [...new Set(wordsToUse.filter(item => item && !usedWordsDataLowerMap?.[item.toLowerCase()]))];
    },
  },
  computed: {
    computedMetaData() {
      if (this.selectedLocales.length === 0) {
        return this.localTableData?.metas;
      }
      if (this.localTableData?.metas === undefined) {
        return [];
      }

      let meta = {};
      this.selectedLocales.forEach((code) => {
        if (this.localTableData?.metas[code]) {
          meta[code] = this.localTableData?.metas[code];
        }
      });

      return meta;
    },
  },
  watch: {
    tableData(newVal, oldVal) {
      this.localTableData = {...newVal};
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>