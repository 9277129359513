<template>
  <div class="pie-chart"
       :class="classes"
       :style="{width: width, height: height}">

    <template v-if="store === 'google-play'">
      <div class="circle part1 animate"
           :class="[donutData?.title_appearance]">
        <span class="chart-number">1</span>
      </div>
      <div class="circle part2 animate"
           :class="[donutData?.short_description_appearance]">
        <span class="chart-number">2</span>
      </div>
      <div class="circle part3 animate"
           :class="[donutData?.description_appearance]">
        <span class="chart-number">3</span>
      </div>
    </template>

    <template v-else>
      <div class="circle part4 animate"
           :class="[donutData?.title_appearance]">
        <span class="chart-number">1</span>
      </div>
      <div class="circle part5 animate"
           :class="[donutData?.short_description_appearance]">
        <span class="chart-number">2</span>
      </div>
    </template>

    <div class="separator vertical"></div>
    <div v-if="store === 'google-play'" class="separator horizontal half"
         :class="{'rotate30degs': store === 'google-play'}"></div>
  </div>
</template>

<script>
import Profile from "../../../views/Account/Profile";

export default {
  name: "TitleDescriptionDonut",
  components: {Profile},
  props: {
    width: {
      type: String,
      default: '12px'
    },
    height: {
      type: String,
      default: '12px'
    },
    store: {
      type: String,
      default: 'app-store'
    },
    showNumbers: {
      type: Boolean,
      default: false
    },
    donutData: {
      type: Object,
    },
    classes: {
      type: String,
    }
  },
}
</script>

<style lang="scss">
.pie-chart {
  position: relative;
  margin: 0 auto;
  cursor: pointer;

  &:before {
    //content: '';
    //width: 40%;
    //height: 40%;
    //border-radius: 50%;
    //position: absolute;
    //z-index: 15;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //background-color: #fff;
  }


  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    background-color: #d0d9eb;
  }

  .chart-number {
    position: absolute;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    z-index: 5;
  }

  .animate {
    -webkit-transition: 0.2s cubic-bezier(.74, 1.13, .83, 1.2);
    -moz-transition: 0.2s cubic-bezier(.74, 1.13, .83, 1.2);
    -o-transition: 0.2s cubic-bezier(.74, 1.13, .83, 1.2);
    transition: 0.2s cubic-bezier(.74, 1.13, .83, 1.2);
  }

  .part1 {
    -webkit-clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);

    .chart-number {
      top: 40%;
      left: 22%;
    }
  }

  .part2 {
    -webkit-clip-path: polygon(50% 0, 100% 0, 100% 50%, 50% 50%);
    clip-path: polygon(50% 0, 100% 0, 100% 50%, 50% 50%);

    .chart-number {
      top: 15%;
      right: 20%;
    }
  }

  .part3 {
    -webkit-clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);

    .chart-number {
      bottom: 15%;
      right: 20%;
    }
  }

  .part4 {
    -webkit-clip-path: polygon(50% 0%, 50% 50%, 50% 100%, 0 100%, 0 0);
    clip-path: polygon(50% 0%, 50% 50%, 50% 100%, 0 100%, 0 0);

    .chart-number {
      top: 57%;
      left: 17%;
    }
  }

  .part5 {
    -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);

    .chart-number {
      top: 15%;
      right: 20%;
    }
  }

  .active, .green {
    background-color: #6bd790;
  }

  .yellow {
    background-color: #ffd664;
  }

  .separator {
    width: 100%;
    height: 9%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;

    &.vertical {
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(90deg);

      &.half {
        top: 25%;
      }
    }

    &.horizontal {
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(0deg);

      &.half {
        top: 50%;
        left: 75%;
      }
    }

    &.diagonal {
      transform-origin: left;
      transform: translate(0, -50%) rotate(45deg);
    }

    &.half {
      width: 50%;
    }

    &.rotate30deg {
      transform: translate(0, -50%) rotate(30deg);
    }
  }
}

.pie-chart.small-pie {
  .chart-number {
    opacity: 0;
  }
}

</style>