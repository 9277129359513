<template>
  <transition>
    <div class="sliding-window-container prevent-select"
         :class="[currentClass, classes]"
         id="sliding-panel"
         @mouseover="mouseOver"
         @mouseleave="mouseLeave"
         @mousedown="panelMouseDown"
         @mouseup="panelMouseUp"
         v-click-outside="outside"
         :style="{width: localWidth, minWidth: localMinWidth}">
      <div class="sliding-inner">
        <div class="sliding-panel-heading">
          <div class="close-btn"
               @click="closePanel">
            <svg-icon icon="close"/>
            <div class="text">Close</div>
          </div>
        </div>
        <div class="sliding-panel-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {setCookie, getCookie} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "SlidingWindow",
  components: {SvgIcon},
  props: {
    width: {
      type: String,
      default: '75%',
    },
    minWidth: {
      type: String,
      default: '450px',
    },
    showPanel: {
      type: Number,
      default: 0,
    },
    classes: {
      type: String,
    }
  },
  data() {
    return {
      m_pos: 0,
      BORDER_SIZE: 4,
      panel: null,
      currentClass: 'transition',
      localWidth: 0,
      localMinWidth: 0,
    }
  },
  methods: {
    resize(e) {
      this.currentClass = '';
      const dx = this.m_pos - e.x;
      this.m_pos = e.x;

      let width = parseInt(this.panel.style.width);

      if (this.panel.style.width?.includes('%')) {
        width = (window.innerWidth / 100) * parseInt(this.panel.style.width);
      }

      if (width > parseInt(this.minWidth) - 10 && (width < window.innerWidth)) {
        this.panel.style.width = (parseInt(getComputedStyle(this.panel, '').width) + dx) + "px";
      } else if (width <= parseInt(this.minWidth) - 10) {
        this.panel.style.width = parseInt(this.minWidth) + 'px'
        this.panelMouseUp();
      } else if (width > window.innerWidth - 18) {
        this.panel.style.width = window.innerWidth - 18 + 'px';
        this.panelMouseUp();
      }
    },
    panelMouseDown(e) {
      if (e.offsetX < this.BORDER_SIZE) {
        this.m_pos = e.x;
        document.addEventListener("mousemove", this.resize, false);
      }
    },
    panelMouseUp(e) {
      document.removeEventListener("mousemove", this.resize, false);
    },
    closePanel() {
      this.setPanelWidthCookie();
      this.panel.style.width = 0;
      this.panel.style.minWidth = 0;
      this.panel.style.paddingLeft = 0;

      this.enableScroll();
      this.$emit('closePanel')
    },
    mouseOver(e) {
      this.disableScroll();
    },
    mouseLeave(e) {
      this.enableScroll();
    },
    outside(e) {
      const openedModals = document.querySelectorAll('.modal-container .modal');

      if (this.panel.style.width === '0px') {
        return;
      }

      if (e?.target?.closest('table')?.classList?.contains('advanced-custom-table')) {
        return;
      }

      if (openedModals?.length > 0) {
        return;
      }

      if (!e.target.classList?.contains('word-container') && !e.target?.closest('div')?.classList?.contains('word-container') && this.showPanel >= 1) {
        this.setPanelWidthCookie();
        this.panel.style.width = 0;
        this.panel.style.paddingLeft = 0;
        this.panel.style.minWidth = 0;
      } else if (e.target.classList?.contains('word-container') && this.showPanel >= 1) {
        setTimeout(() => {
          this.setPanelWidthCookie();
        }, 350);
      } else {
        setTimeout(() => {
          this.setPanelWidthCookie();
        }, 350);
      }
    },
    setPanelWidthCookie() {
      let width = this.panel.style.width;
      if (this.panel.style.width?.includes('%')) {
        width = parseInt(this.panel.style.width) + 'percents';
      }

      if (width !== '0px') {
        setCookie('sidePanelWidth', width, process.env.VUE_APP_COOKIE_DOMAIN, 365);
      }

    },
    disableScroll() {
      let scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft =
          window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    },
    enableScroll() {
      window.onscroll = function () {};
    },
  },
  mounted() {
    const sidePanelWidthCookie = getCookie('sidePanelWidth');
    this.panel = document.getElementById('sliding-panel');
    this.currentClass = 'transition';

    if (sidePanelWidthCookie) {

      let width = sidePanelWidthCookie !== '0px' ? sidePanelWidthCookie : '75%';

      if (sidePanelWidthCookie?.includes('percents')) {
        width = parseInt(sidePanelWidthCookie) + '%';

        let string = sidePanelWidthCookie.replace('percents', '');
        string = string?.trim();
      }
      this.localWidth = width;
      this.panel.style.paddingLeft = 4 + 'px';
    } else {
      setCookie('sidePanelWidth', '75percent', process.env.VUE_APP_COOKIE_DOMAIN, 365);
      this.localWidth = this.width;
      this.panel.style.paddingLeft = 4 + 'px';
    }

    if (this.showPanel === 0) {
      this.panel.style.width = 0;
      this.localWidth = 0;
      this.localMinWidth = 0;
      this.panel.style.minWidth = 0;
      // this.panel.style.paddingLeft = 0;
    }

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
        if (isNotCombinedKey) {
          this.closePanel();
        }
      }
    });
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
    ]),
  },
  watch: {
    showPanel(newVal, oldVal) {
      if (this.panel.style.width !== '0px') {
        this.currentClass = 'transition';
        if (this.panel.style.width) {
          let width = this.panel.style.width;
          this.panel.style.width = width;
          this.localWidth = width;
          this.localMinWidth = this.minWidth;
          this.panel.style.minWidth = this.minWidth;
          this.panel.style.paddingLeft = 4 + 'px';
        }
      } else {
        const sidePanelWidthCookie = getCookie('sidePanelWidth');
        this.currentClass = 'transition';

        if (sidePanelWidthCookie) {
          let width = sidePanelWidthCookie;
          // let width = this.panel.style.width;
          if (sidePanelWidthCookie?.includes('percents')) {
            width = parseInt(sidePanelWidthCookie) + '%';

            let string = sidePanelWidthCookie.replace('percents', '');
            string = string?.trim();
          }

          // setTimeout(() => {
          this.panel.style.width = width;
          this.localWidth = width;
          this.localMinWidth = this.minWidth;
          this.panel.style.minWidth = this.minWidth;
          this.panel.style.paddingLeft = 4 + 'px';
        }
      }
    },
    currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        this.closePanel();
      }
    },
    currentCountryManual(newVal, oldVal) {
      if (newVal?.code !== oldVal?.code) {
        this.closePanel();
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>