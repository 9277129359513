<template>
  <tr class="locale-row">
    <td class="locale-name">
      <div class="inner-content">{{ localData?.locale?.name }}</div>
    </td>
    <td class="words">
      <div class="inner-content">
        <span v-html="analyzedData"
              class="text-html"></span>
      </div>
    </td>
    <td class="options">
      <div class="inner-content">
        <template v-if="(localeKeywordsLength + uncoveredKeywordsLength) >= 100">
          <svg-icon icon="plus"
                    class="add-uncovered red disabled"/>
          <div class="info-tooltip">
            Reached character limit for keywords <span class="red">{{ localeKeywordsLength }}/100</span>
          </div>
        </template>
        <template v-else>

          <div v-show="inactiveIsSet && addClicked">
            <svg-icon icon="check"
                      class="add-uncovered check green active"/>
          </div>

          <div v-show="(inactiveIsSet && !addClicked)">
            <svg-icon icon="plus"
                      class="add-uncovered disabled"/>
          </div>

          <div v-show="(!inactiveIsSet && !addClicked)">
            <svg-icon icon="plus"
                      class="add-uncovered green active"
                      @click="saveUncoveredWords(localData?.locale?.code)"/>
          </div>
        </template>
      </div>
    </td>
  </tr>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {mapGetters} from "vuex";
import {splitTextToParts} from "@/utils/utils";

export default {
  name: "UncoveredTooltipTableRow",
  components: {SvgIcon},
  props: {
    data: {
      type: Object,
    },
    keyword: {
      type: String,
    },
    editableMetas: {
      type: Object,
    },
    stateChanged: {
      type: Number
    }
  },
  emits: ['save-uncovered-words'],
  data() {
    return {
      uncoveredWords: [],
      addClicked: false,
      localeKeywordsLength: 0,
      uncoveredKeywordsLength: 0,
      localData: {},
      localKeyword: '',
      analyzedData: '',
      inactiveIsSet: false,
    }
  },
  methods: {
    analyzeString(coverage) {
      let localKeywordArray = splitTextToParts(this.localKeyword);

      let missingWordsIndex = [];
      if (this.localData?.missing?.length > 0) {
        this.localData.missing.forEach((word) => {missingWordsIndex[word.toLowerCase()] = word});
      }

      let coveredWordsIndex = coverage;

      let resultStrArr = [];
      localKeywordArray.forEach((word) => {
        if (word === ' ') {
          resultStrArr.push("&nbsp;");
          return;
        }

        let lowerWord = word.toLowerCase();
        if (missingWordsIndex[lowerWord]) {
          resultStrArr.push(`<span class="red">${word}</span>`);
        } else if (coveredWordsIndex[lowerWord] === 'covered') {
          resultStrArr.push(`<span class="green">${word}</span>`);
        } else if (coveredWordsIndex[lowerWord] === 'covered_in_title') {
          resultStrArr.push(`<strong class="green">${word}</strong>`);
        } else {
          resultStrArr.push(`<span class="default-text-color">${word}</span>`);
        }
      });

      this.analyzedData = resultStrArr.join('');
    },
    saveUncoveredWords(localeCode) {
      this.addClicked = true;
      this.$emit('save-uncovered-words', {
        localeCode: localeCode,
        words_to_use: this.localData?.missing,
        keyword: this.keyword,
      });
    },
    uncoveredWordsSanitized() {
      this.uncoveredKeywordsLength = this.localData?.missing[0]?.length;
    },
    detectLocaleKeywordsLength() {
      let totalCharsLength = 0;
      const metasKeywordsList = this.editableMetaData?.metas[this.data?.locale?.code]?.keywords?.list;
      for (const key in metasKeywordsList) {
        totalCharsLength += metasKeywordsList[key]?.length;
      }

      totalCharsLength += metasKeywordsList?.length - 1;
      this.localeKeywordsLength = totalCharsLength;
    },
  },
  mounted() {
    if (this.data) {
      this.localData = {...this.data};
    }
    this.localKeyword = this.keyword;

    this.analyzeString(this.localData?.coverage_by_word);
    this.detectLocaleKeywordsLength();
    this.uncoveredWordsSanitized();
  },
  computed: {
    ...mapGetters([
      'editableMetaData',
    ]),
  },
  watch: {
    stateChanged(newVal, oldVal) {
      this.inactiveIsSet = true;
    },
    editableMetaData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal) && this.addClicked) {
        let splittedKeyword = this.localKeyword?.split(' ');
        const currLocaleMetas = newVal?.metas[this.data?.locale?.code]?.keywords?.list;

        let coverageByWord = {};

        if (splittedKeyword?.length > 0) {
          for (const key in splittedKeyword) {
            const idx = currLocaleMetas?.findIndex(item => item === splittedKeyword[key]);
            if (idx >= 0) {
              coverageByWord[splittedKeyword[key]] = 'covered';
            }
          }
        }

        coverageByWord = {...coverageByWord, ...this.localData?.coverage_by_word}

        if (this.localData) {
          this.localData.coverage_by_word = coverageByWord;
          this.localData.missing = [];
        }

        this.analyzeString(coverageByWord);
        this.detectLocaleKeywordsLength();
        this.uncoveredWordsSanitized();
      }
    }
  }
}
</script>

<style lang="scss"></style>