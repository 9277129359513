export const competitorsMetaTableConfig = {
    headers: [
        {
            text: 'Logo',
            value: 'logo',
            sortName: 'logo',
            sortBy: 'logo',
            align: 'left',
            width: '76px',
            sortableIconAlign: 'right',
            isActive: true,
            headingClass: 'keywords-col',
        },
        {
            text: 'Title',
            value: 'title',
            sortName: 'title',
            sortBy: 'title',
            align: 'left',
            // width: '410px',
            width: 'calc(100% - ((76px + 118px + 118px) / 2)',
            sortableIconAlign: 'right',
            isActive: true,
            headingClass: 'keywords-col',
        },
        {
            text: 'Subtitle',
            value: 'sub_title',
            sortName: 'sub_title',
            sortBy: 'sub_title',
            align: 'left',
            // width: '410px',
            width: 'calc(100% - ((76px + 118px + 118px) / 2)',
            sortableIconAlign: 'right',
            isActive: true,
            headingClass: 'keywords-col',
        },
        {
            text: 'Installs per <br/> country',
            value: 'installs',
            sortName: 'installs',
            sortBy: 'installs',
            align: 'right',
            width: '118px',
            sortableIconAlign: 'left',
            isActive: true,
        },
        {
            text: 'Revenue per<br/> country',
            value: 'revenue',
            sortName: 'revenue',
            sortBy: 'revenue',
            align: 'right',
            width: '118px',
            sortableIconAlign: 'left',
            isActive: true,
        },
    ],
}