<template>
  <div class="meta-editor-content">
    <h1 class="page-title mobile-visible">Meta Editor</h1>
    <div class="meta-editor-cols">
      <div class="text-col">
        <div class="col-header">
          Meta Editor Tool is a powerful feature of Asolytics that helps you to optimize your app's metadata for
          app stores and improve its visibility and search ranking.
        </div>
        <div class="col-content">
          <div class="text-item">
            1️⃣ Stand out in the crowded app store by using relevant keywords.
          </div>
          <div class="text-item">
            2️⃣ Ability to work with several locales at one time.
          </div>
          <div class="text-item">
            3️⃣ A user-friendly interface for editing metadata, making the process of ASO simple and efficient.
          </div>
          <div class="text-item">
            4️⃣ Optimize your meta for most popular keywords that can increase the number of installs for your
            app.
          </div>
        </div>

        <div class="btn-block"
             v-if="showNotifyBtn">
          <base-button height="40px"
                       width="160px"
                       font-size="15px"
                       border-radius="4px"
                       :mode="'btn-standard'"
                       @click="notifyMeClicked">Notify Me
          </base-button>
        </div>
      </div>
    </div>
    <div class="image-col">
      <div class="image">
        <img
            src="@/assets/images/meta-editor/meta-editor-preview-full.png"
            alt="Illustration">
      </div>
    </div>

    <custom-modal :opened="showSuccessModal"
                  :max-width="540"
                  class-name="request-feature-modal"
                  @closeModal="closeModal('showSuccessModal')">
      <div class="modal-message">
        <div class="success-modal-text">
          Thank you! We will notify you 🙌
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import {getCookie, setCookie} from "@/utils/utils";

export default {
  name: "NotifyMeComponent",
  data() {
    return {
      showSuccessModal: false,
      showNotifyBtn: true,
    }
  },
  methods: {
    async notifyMeClicked() {
      const formdata = new FormData();
      formdata.append("author_name", this.userData.name);
      formdata.append("author_email", this.userData.email);
      formdata.append("content", 'Notify me!');
      formdata.append("type", "notify-me");
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.CONTACTS, formdata);

      if (!result?.errors && !result?.message) {
        this.showNotifyBtn = false;
        this.showSuccessModal = true;

        const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
        const days = 365;
        setCookie('notifyMe', true, cookieDomain, days);
      } else {
        this.errors = result.errors ? {...result.errors} : {...result.message};
        this.showSuccessModal = false;
      }
    },
  },
  async mounted() {
    this.showNotifyBtn = !getCookie('notifyMe');
  },
  computed: {
    ...mapGetters([
      'userData',
      'isMobile',
      'sidebarIsCollapsed'
    ]),
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>