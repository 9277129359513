<template>
  <div class="custom-tooltip"
       :class="classes">

    <div class="slot-trigger">
      <slot name="slotTrigger"></slot>
    </div>
    <div class="tooltip-content-inner">
      <slot name="slotContent"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicTooltip",
  props: {
    classes: {
      type: String,
      default: ''
    },
  },
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>