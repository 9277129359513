export const keywordsRankingChartOptions = {
    chart: {
        type: 'area',
        width: 70,
        height: 60,
    },
    title: false,
    legend: {
        enabled: false
    },
    xAxis: {
        title: false,
        categories: [],
        labels: {
            enabled: false
        },
        gridLineColor: '#ffffff',
        visible: false
    },
    yAxis: {
        title: false,
        labels: {
            enabled: false
        },
        gridLineColor: '#ffffff',
        reversed: true,
    },
    plotOptions: {
        series: {
            color: '#7688ff',
            fillColor: {
                linearGradient: [0, 0, 0, 0],
                stops: [
                    [0, '#fff'],
                    [1, '#fff']
                ]
            },
            marker: {
                enabled: false
            },
            states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    halo: {
                        size: 4,
                        attributes: {
                            fill: 'transparent',
                            'stroke-width': 2,
                            stroke: 'transparent'
                        }
                    }
                }
            }
        }
    },
}