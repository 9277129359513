<template>
  <div class="meta-words-tab-wrap">
    <div v-if="wordsLoaded && wordsTableDataArray?.length > 0"
         class="controls">
      <languages-filter :languages="languages"
                        @apply="selectedLanguages = [...$event]"/>

      <switch-toggle @toggle-changed="handleToggleChange" :parentToggleModel="selectedMode">
        <template v-slot:optionOne>
          <custom-tooltip width="180px">
            <template v-slot:slotTrigger>
              Use In
            </template>
            <template v-slot:slotContent>
              This mode is best to start writing your Titles and Subtitles
            </template>
          </custom-tooltip>
        </template>
        <template v-slot:optionTwo>
          <custom-tooltip width="180px">
            <template v-slot:slotTrigger>
              Add to Kw
            </template>
            <template v-slot:slotContent>
              This mode is used to add words to the Keywords field
            </template>
          </custom-tooltip>
        </template>
      </switch-toggle>
    </div>
    <div class="tables">
      <SimpleInfoTable ref='wordsTable'
                       :data-provider="wordsDataProvider"
                       :columns="{
                         word: {
                           header: 'Word',
                           orientation: 'left',
                           sortBy: 'word',
                           tooltip: 'We break down your keywords into individual words, helping you pick the best ones for localization. This way, you can choose popular words to target more high-traffic searches. Click on a word to see the keywords that include it.',
                         },
                         coverage: {header: '', alt: 'Store Coverage', tooltip: 'App Store Coverage'},
                         coverage_redaction: {
                           header: '',
                           hidden: !currentRedactionId,
                           alt: 'Redaction Coverage',
                           tooltip: 'Redaction Coverage',
                         },
                         locale: {header: 'Locale', orientation: 'left'},
                         popularity: {
                           header: 'Tot. Pop.',
                           sortBy: 'total_popularity',
                           alt: 'Total Popularity',
                           tooltip: 'Total Popularity - the sum of the popularity scores of all keywords that contain this word.',
                         },
                         impression: {
                           header: 'Tot. KDI',
                           sortBy: 'total_daily_impression',
                           alt: 'Total KDI',
                           tooltip: 'Total Estimated Keyword Daily Impressions - the sum of estimated impressions for all keywords containing this word.',
                         },
                         character_count: {
                           header: '# Let.',
                           sortBy: 'character_count',
                           alt: 'Number of letters',
                           headerStyle: {minWidth: '92px'},
                           tooltip: 'Number of letters - the total number of characters in this word.',
                         },
                         keywords_count: {
                           header: '# Kws.',
                           sortBy: 'total_keywords',
                           alt: 'Number of Keywords',
                           headerStyle: {minWidth: '98px'},
                           tooltip: 'Number of Keywords - the number of keywords that include this word.',
                         },
                       }"
                       :search-by="['word']"
                       default-sort-direction="desc"
                       default-sort-index="popularity"
                       :tr-click-callback="wordClickCallback"
                       :tr-class-callback="trClassCallback"
                       :configurable-columns="true"
                       table-id="meta-editor-words-table"
      >
        <template v-slot:headersAfter="slotProps">
          <div v-if="slotProps.index === 'word'">
            <translate-tool :active="allWordsTranslated" @translate-clicked="translateAllWords"/>
          </div>
        </template>

        <template v-slot:headers="slotProps">
          <div v-if="slotProps.index === 'coverage'" class="header-icon">
            <coverage-tooltip>
              <img src="@/assets/images/icons/app_store_gray.svg"
                   width="16"
                   height="16">
            </coverage-tooltip>
          </div>
          <div v-else-if="slotProps.index === 'coverage_redaction'" class="header-icon">
            <coverage-tooltip>
              <svg-icon icon="document" />
            </coverage-tooltip>
          </div>
          <div v-else-if="slotProps.index === 'locale'" class="header-locale">
            <div v-for="locale in selectedLocalesWithTitles"
                 v-if="isKeywordsMode"
                 class="locale-wrap">
              <div class="locale">
                <div class="title">
                  <custom-tooltip width="300px">
                    <template v-slot:slotTrigger>
                      {{ locale.code.toUpperCase() }}
                    </template>
                    <template v-slot:slotContent>
                      <span style="font-weight: 600;">{{ locale.title }} Locale.</span> Review and compare where a word is used in the current App Store metadata and the new edited metadata you create using our metaeditor. Click the checkbox to add this word to the Keywords field for this locale.
                    </template>
                  </custom-tooltip>
                </div>
                <div class="icons">
                  <div>
                    <img src="@/assets/images/icons/app_store_gray.svg"
                         width="16"
                         height="16">
                  </div>
                  <div>
                    <svg-icon icon="document" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="locale-text">
              <custom-tooltip width="250px">
                <template v-slot:slotTrigger>
                  {{ slotProps.header }}
                </template>
                <template v-slot:slotContent>
                  Select in which localization and in which field you want to use a word (Title, Subtitle or Keywords)
                </template>
              </custom-tooltip>
            </div>
          </div>
          <div v-else>
            <custom-tooltip width="250px">
              <template v-slot:slotTrigger>
                {{ slotProps.header }}
              </template>
              <template v-slot:slotContent>
                {{ slotProps.tooltip }}
              </template>
            </custom-tooltip>
          </div>
        </template>

        <template v-slot:items-word="slotProps">
          <div class="word">
            <div>{{ slotProps.item.word }}</div>
            <div v-show="slotProps.item.translate"
                  class="translated-block"
                  v-html="slotProps.item.translate"></div>
          </div>
        </template>
        <template v-slot:items-coverage="slotProps">
          <meta-coverage-indicator :coverage="slotProps.item.meta_entry"
                                   :locales-titles="localesData.popular"
                                   :redaction-coverage="slotProps.item.meta_entry_redaction"
                                   :word="slotProps.item.word"  />
        </template>
        <template v-slot:items-coverage_redaction="slotProps">
          <meta-coverage-indicator :coverage="slotProps.item.meta_entry"
                                   :is-redaction-mode="true"
                                   :locales-titles="localesData.popular"
                                   :redaction-coverage="slotProps.item.meta_entry_redaction"
                                   :word="slotProps.item.word" />
        </template>
        <template v-slot:items-locale="slotProps">
          <select-meta-word-use-in :word="slotProps.item.word"
                                   :keywords-mode="isKeywordsMode"
                                   :locales-with-titles="selectedLocalesWithTitles"
                                   :locale-to-use-in-title="slotProps.item.to_use_in_title"
                                   :locale-to-use-in-subtitle="slotProps.item.to_use_in_subtitle"
                                   :coverage="currentRedactionId ? slotProps.item.meta_entry_redaction : slotProps.item.meta_entry"
                                   :store-coverage="slotProps.item.meta_entry"
                                   :editable-metas="editableMetas"
                                   @apply="applyUseInTooltip"
                                   @remove-keyword="removeMetaKeyword" />
        </template>
        <template v-slot:items-popularity="slotProps">
          {{ shortenDigits(slotProps.item.total_popularity) }}
        </template>
        <template v-slot:items-impression="slotProps">
          {{ shortenDigits(slotProps.item.total_daily_impression) }}
        </template>
        <template v-slot:items-character_count="slotProps">
          {{ slotProps.item.character_count }}
        </template>
        <template v-slot:items-keywords_count="slotProps">
          {{ slotProps.item.total_keywords }}
        </template>
      </SimpleInfoTable>

      <SimpleInfoTable v-show="wordsLoaded"
                       :data-provider="keywordsDataProvider"
                       :columns="{
                         keyword: {header: 'Keyword', orientation: 'left', sortBy: 'keyword'},
                         coverage: {header: '', alt: 'Store Coverage', tooltip: 'App Store Coverage'},
                         coverage_redaction: {
                           header: '',
                           hidden: !currentRedactionId,
                           alt: 'Redaction Coverage',
                           tooltip: 'Redaction Coverage',
                         },
                         popularity: {
                           header: 'Popul.',
                           sortBy: 'popularity',
                           alt: 'Popularity',
                           tooltip: 'Popularity - App Store popularity ranges from 5-100, with a score of 5 indicating low or no search volume. We assign a special value of 1 in cases where a popularity value of 5 is received consistently from the App Store for over 30 days, indicating extremely low or no search activity.',
                         },
                         impression: {
                           header: 'KDI',
                           sortBy: 'daily_impression',
                           tooltip: 'Estimated Keyword Daily Impressions - this metric shows the estimated number of impressions a keyword receives each day in a given country. This metric indicates keyword popularity and does not reflect the number of impressions your app will get based on its ranking.',
                         },
                         app_rank: {
                           header: 'App Rank',
                           tooltip: 'App Rank - your app\'s ranking for this keyword in the App Store search results for the specified country.',
                           sortBy: (item) => sortByRank(item.latest_rank.rank),
                           sortExportParam: 'latest_rank',
                         },
                         results: {
                           header: 'Res.',
                           sortBy: 'results',
                           alt: 'Results',
                           tooltip: 'Results - the number of apps that rank for this keyword in the App Store search results.'
                         },
                         competitors: {header: 'Comp. in Top 10', sortBy: (item) => item.competitors_top_10.competitors ?? 0},
                       }"
                       :search-by="['keyword']"
                       default-sort-direction="desc"
                       default-sort-index="popularity"
                       :configurable-columns="true"
                       table-id="meta-editor-keywords-table"
      >
        <template v-slot:headersAfter="slotProps">
          <div v-if="slotProps.index === 'keyword'">
            <translate-tool :active="allKeywordsTranslated" @translate-clicked="translateAllKeywords"/>
          </div>
        </template>

        <template v-slot:headers="slotProps">
          <div v-if="slotProps.index === 'coverage'" class="header-icon">
            <coverage-tooltip :is-keywords-mode="true">
              <img src="@/assets/images/icons/app_store_gray.svg"
                   width="16"
                   height="16">
            </coverage-tooltip>
          </div>
          <div v-else-if="slotProps.index === 'coverage_redaction'" class="header-icon">
            <coverage-tooltip :is-keywords-mode="true">
              <svg-icon icon="document" />
            </coverage-tooltip>
          </div>
          <div v-else>
            <custom-tooltip width="250px" v-if="slotProps.tooltip">
              <template v-slot:slotTrigger>
                {{ slotProps.header }}
              </template>
              <template v-slot:slotContent>
                {{ slotProps.tooltip }}
              </template>
            </custom-tooltip>
            <template v-else>
              {{ slotProps.header }}
            </template>
          </div>
        </template>

        <template v-slot:items-keyword="slotProps">
          <div class="word">
            <div>{{ slotProps.item.keyword }}</div>
            <div v-show="slotProps.item.translate"
                  class="translated-block"
                  v-html="slotProps.item.translate"></div>
          </div>
        </template>
        <template v-slot:items-coverage="slotProps">
          <meta-coverage-indicator :coverage="slotProps.item.meta_entry"
                                   :is-keywords-mode="true"
                                   :locales-titles="localesData.popular"
                                   :redaction-coverage="slotProps.item.meta_entry_redaction"
                                   :word="slotProps.item.keyword" />
        </template>
        <template v-slot:items-coverage_redaction="slotProps">
          <meta-coverage-indicator :coverage="slotProps.item.meta_entry"
                                   :is-redaction-mode="true"
                                   :is-keywords-mode="true"
                                   :locales-titles="localesData.popular"
                                   :redaction-coverage="slotProps.item.meta_entry_redaction"
                                   :word="slotProps.item.keyword" />
        </template>
        <template v-slot:items-popularity="slotProps">
          {{ slotProps.item.popularity }}
        </template>
        <template v-slot:items-impression="slotProps">
          {{ shortenDigits(slotProps.item.daily_impression) }}
        </template>
        <template v-slot:items-app_rank="slotProps">
          <div class="rank-value">
            <app-rank-tool :item="slotProps.item?.latest_rank"/>
          </div>
        </template>
        <template v-slot:items-results="slotProps">
          {{ shortenDigits(slotProps.item.results) }}
        </template>
        <template v-slot:items-competitors="slotProps">
          <competitors-in-top-tool :competitors="slotProps.item.competitors_top_10.competitors"
                                   :indirect-competitors="slotProps.item.competitors_top_10.indirect_competitors"
                                   :non-competitots="slotProps.item.competitors_top_10.non_competitors"
                                   :total="10"
                                   @clicked="liveSearchTriggerHandler(slotProps.item, 'db')" />
        </template>
      </SimpleInfoTable>
    </div>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import {AppMetaKeywordsObject, AppMetaWordsObject, GenerateQueryUrl} from "@/utils/factories";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import translates from "@/api/modules/translates";
import {openGlobalModal, shortenDigits} from "@/utils/utils";
import MetaCoverageIndicator from "@/views/MetaEditor/components/MetaCoverageIndicator/index.vue";
import SelectMetaWordUseIn from "@/views/MetaEditor/components/SelectMetaWordUseIn/index.vue";
import BaseButton from "@/components/UI/BaseButton/index.vue";
import LanguagesFilter from "@/views/MetaEditor/components/LanguagesFilter/index.vue";
import SwitchToggle from "@/components/UI/SwitchToggle/index.vue";
import {SimpleDataProvider} from "@/api/modules/tables/simple-data-provider";
import CompetitorsInTopTool from "@/components/DataTables/Tools/CompetitorsInTopTool/index.vue";
import LiveSearchGlobalModalAdapter from "@/components/Modals/LiveSearchModal/LiveSearchGlobalModalAdapter.vue";
import {SortingData} from "@/api/modules/tables/search-filter-data";
import CoverageTooltip from "@/views/MetaEditor/components/CoverageTooltip/index.vue";

export default {
  name: "Words",
  components: {
    CoverageTooltip,
    CompetitorsInTopTool,
    SwitchToggle,
    LanguagesFilter,
    BaseButton,
    SelectMetaWordUseIn,
    MetaCoverageIndicator,
    SvgIcon,
    AppRankTool,
    PreloaderTableSmall,
    SimpleInfoTable,
    TranslateTool,
  },
  emits: ['save-table-words', 'remove-meta-keyword'],
  props: {
    countryCode: {
      type: String
    },
    currentCountryCode: {
      type: String
    },
    currentCountryName: {
      type: String
    },
    currentRedactionId: {
      type: String
    },
    currentFilterReset: {
      type: Number,
    },
    countriesList: {
      type: Array,
    },
    editableMetas: {
      type: Object,
    },
    selectedLocalesWithTitles: {
      type: Array,
    },
    appliedFilters: {
      type: Object,
    },
    localesData: {
      type: Object,
    },
  },
  data() {
    return {
      wordsLoaded: false,
      keywordsLoaded: false,
      wordsTableData: {},
      languages: [],
      selectedLanguages: [],
      keywordsTableData: [],
      selectedWord: null,
      selectedMode: JSON.parse(localStorage.getItem('metaEditorWordsTabSelectedMode')) || false,
      wordsDataProvider: new SimpleDataProvider([], null, ['word'], null, new SortingData(
        'total_popularity',
        'desc'
      )),
      keywordsDataProvider: new SimpleDataProvider([], null, ['keyword'], null, new SortingData(
        'total_popularity',
        'desc'
      )),
    }
  },
  created() {
    this.wordsDataProvider.setLoading(true);
    this.keywordsDataProvider.setLoading(true);
  },
  async mounted() {
    await this.fetchCountryCatalog();
    await this.fetchWordsTableData();
  },
  methods: {
    shortenDigits,
    liveSearchTriggerHandler(e, flow = 'live') {
      openGlobalModal(LiveSearchGlobalModalAdapter, {
        'country': this.currentCountry,
        'flow': flow,
        'keyword': e.keyword
      })
    },
    trClassCallback(item) {
      return {'active': this.selectedWord && item.word === this.selectedWord.word};
    },
    removeMetaKeyword(locale, word) {
      this.wordsTableData[word].meta_entry_redaction.keywords[locale].level = 'zero';
      this.wordsTableData = {...this.wordsTableData};
      this.$emit(
        'remove-meta-keyword',
        locale,
        word,
      );
    },
    applyUseInTooltip(locale, newWordToUseInTitle, newWordToUseInSubtitle, newKeywordToUse) {
      this.$emit(
        'save-table-words',
        locale,
        [newWordToUseInTitle].filter(item => item !== null),
        [newWordToUseInSubtitle].filter(item => item !== null),
        [newKeywordToUse].filter(item => item !== null),
      );

      if (newWordToUseInTitle) {
        this.wordsTableData[newWordToUseInTitle].to_use_in_title = locale;
        this.wordsTableData[newWordToUseInTitle].to_use_in_subtitle = null;
      }

      if (newWordToUseInSubtitle) {
        this.wordsTableData[newWordToUseInSubtitle].to_use_in_subtitle = locale;
        this.wordsTableData[newWordToUseInSubtitle].to_use_in_title = null;
      }

      if (newKeywordToUse) {
        if (this.wordsTableData[newWordToUseInSubtitle]) {
          this.wordsTableData[newWordToUseInSubtitle].to_use_in_subtitle = null;
          this.wordsTableData[newWordToUseInSubtitle].to_use_in_title = null;
        }

        this.wordsTableData[newKeywordToUse].meta_entry_redaction.keywords[locale].level = 'full';
      }
    },
    wordClickCallback(item, event) {
      if (event.target?.closest('td')?.querySelector('.select-meta-usage-cell') !== null) {
        return;
      }

      if (this.selectedWord.word !== item.word) {
        this.selectedWord = {...item};
      }
    },
    async fetchWordsTableData() {
      this.wordsLoaded = false;
      const wordsData = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_WORDS + this.wordsRequestUrl
      );

      const words = AppMetaWordsObject(wordsData?.list, this.countryList);
      let wordsObject = {};
      words.forEach((word) => {
        wordsObject[word.word] = word;
      });
      this.wordsTableData = wordsObject;
      this.selectedWord = this.wordsTableDataArray.length > 0 ? {...this.wordsTableDataArray[0]} : null;
      this.languages = wordsData?.languages;
      this.wordsLoaded = true;
    },
    async fetchKeywordsTableData() {
      if (this.selectedWord === null) {
        this.keywordsTableData = [];
        return;
      }

      this.keywordsLoaded = false;
      let url = `?app_id=${this.currentApp?.id}&country_code=${this.countryCode}`
        + '&filter[with_word][query]=' + this.selectedWord.word;

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }

      if (this.appliedFilters) {
        url += GenerateQueryUrl(this.appliedFilters);
      }
      url += '&sort_by=popularity&order=desc';

      const wordsData = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_KEYWORDS + url
      );

      this.keywordsTableData = AppMetaKeywordsObject(wordsData?.list, this.countryList);

      this.keywordsLoaded = true;
    },
    async fetchCountryCatalog() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_LOCALES;
      const result = await httpRequest('GET', url);
      this.$store.dispatch('SET_COUNTRY_LIST', result);
    },
    translateAllWords() {
      this.translateAll('wordsTableData', this.allWordsTranslated);
    },
    translateAllKeywords() {
      this.translateAll('keywordsTableData', this.allKeywordsTranslated);
    },
    async translateAll(dataKey, clear) {
      if (clear) {
        Object.keys(this[dataKey]).forEach((key) => {
          this[dataKey][key].translate = '';
        });
      } else {
        const translatesByKeyword = await translates.translateKeywords(
          this,
          this.currentApp.store.key,
          Object.values(this[dataKey]).map(item => item.word ?? item.keyword)
        );
        Object.keys(this[dataKey]).forEach((key) => {
          this[dataKey][key].translate = translatesByKeyword[this[dataKey][key].word ?? this[dataKey][key].keyword];
        });
      }
    },
    sortByRank(position) {
      return this.isUnknownRank(position) ? -8888 : parseInt(position) * -1;
    },
    isUnknownRank(position) {
      return position === '-' || position === 'N/A';
    },
    handleToggleChange(newMode) {
      this.selectedMode = newMode;
      localStorage.setItem('metaEditorWordsTabSelectedMode', JSON.stringify(newMode));
    }
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'userData',
      'sidebarIsCollapsed',
      'currentCountry',
      'currentCountryManual',
      'isTablet',
      'isMobile',
      'countryList'
    ]),
    ...mapGetters('keywordsMetaData', [
      'sortingMeta',
      'urlQueryString',
    ]),
    allWordsTranslated() {
      return this.wordsTableDataArray.every((item) => {
        return item.translate;
      });
    },
    allKeywordsTranslated() {
      return this.keywordsTableData.every((item) => {
        return item.translate;
      });
    },
    wordsRequestUrl() {
      let url = `?app_id=${this.currentApp?.id}&country_code=${this.countryCode}`;
      if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }
      if (this.appliedFilters) {
        url += GenerateQueryUrl(this.appliedFilters);
      }
      url += '&sort_by=popularity&order=desc';

      return url;
    },
    filteredWordsTableData() {
      if (this.selectedLanguages.length === 0) {
        return this.wordsTableDataArray;
      }

      return this.wordsTableDataArray.filter((item) => {
        return this.selectedLanguages.includes(item.language.code);
      });
    },
    isKeywordsMode() {
      return this.selectedMode === true;
    },
    wordsTableDataArray() {
      return Object.values(this.wordsTableData).sort((a, b) => b.total_popularity - a.total_popularity);
    },
  },
  watch: {
    wordsRequestUrl() {
      this.fetchWordsTableData();
    },
    selectedWord() {
      this.fetchKeywordsTableData();
    },
    filteredWordsTableData() {
      if (this.selectedLanguages.length > 0 && !this.selectedLanguages.some(language => this.selectedWord.language.code === language)) {
        this.selectedWord = this.filteredWordsTableData.length > 0 ? {...this.filteredWordsTableData[0]} : null;
      }
      this.wordsDataProvider.setItems(this.filteredWordsTableData);
    },
    keywordsTableData() {
      this.keywordsDataProvider.setItems(this.keywordsTableData);
    },
    keywordsLoaded() {
      this.keywordsDataProvider.setLoading(!this.keywordsLoaded);
    },
    wordsLoaded() {
      this.wordsDataProvider.setLoading(!this.wordsLoaded);
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>