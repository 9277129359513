<template>
  <div class="uncovered-tooltip-table">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "UncoveredTooltipTable",
  props: {
    data: {
      type: Array,
    },
    initialString: {
      type: String,
    }
  },
  emits: ['save-uncovered-words'],
  data() {
    return {
      uncoveredWords: [],
    }
  },
  methods: {
    analyzeString(coverage) {
      this.uncoveredWords = [];
      if (Object?.keys(coverage)?.length === 0) {
        this.uncoveredWords = this.initialString?.split(' ');
        return `<span class="red">${this.initialString}</span>`;
      } else if (Object?.keys(coverage)?.length > 0) {
        let splittedStr = this.initialString?.split(' ');
        let resultStr = '';

        splittedStr.forEach(word => {
          if (coverage?.hasOwnProperty(word) && coverage[word] === 'covered') {
            resultStr += `<span class="green">${word}</span>&nbsp;`;
          } else if (coverage?.hasOwnProperty(word) && coverage[word] === 'covered_in_title') {
            resultStr += `<strong class="green">${word}</strong>&nbsp;`;
          } else {
            resultStr += `<span class="red">${word}&nbsp</span>`
          }
        });
        this.uncoveredWords = resultStr?.split(' ');
        return resultStr;
      } else {
        this.uncoveredWords = this.initialString?.split(' ');
        return this.initialString;
      }
    },
    saveUncoveredWords(localeCode) {
      const filteredWords = this.uncoveredWords?.filter(word => {
        const noSpecialChars = word.replace(/[^a-zA-Z0-9 ]/g, '');

        if (noSpecialChars !== '') {
          return noSpecialChars;
        }
      });

      this.$emit('save-uncovered-words', {
        localeCode: localeCode,
        words_to_use: filteredWords,
      });
    },
    sanitizeTags(array) {
      let sanitizedArr = [];

      array.forEach(word => {
        const noSpecialChars = word.replace(/[^a-zA-Z0-9 ]/g, '');

        if (noSpecialChars !== '') {
          sanitizedArr.push(noSpecialChars);
        }
      });
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>