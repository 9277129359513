<template>
  <div class="custom-range-input"
       v-click-outside="outside"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, 'direction-' + direction]">

<!--    filterApplied: {{filterApplied}}-->

    <div class="select-styled"
         :class="{'filter-applied': filterApplied}"
         @click="selectDropdownClicked">
      <span id="selected-value">
        <slot></slot>
        <template v-if="filterApplied">:
          <strong>{{ appliedPreset?.title }}</strong>
          <div class="icon"
               @click="cleanFilterClicked">
            <svg-icon icon="close" class="applied-icon"/>
          </div>
        </template>
      </span>
      <div v-if="!filterApplied"
           class="icon">
        <svg-icon icon="angle-down-solid"/>
      </div>
    </div>
    <div class="select-options-wrap"
         :style="{minWidth: minBlockWidth}">
      <div class="bottom-content">
        <div class="presets-block"
             v-if="!noPresets">
          <label class="presets-item"
                 :class="{checked: preset.checked}"
                 v-for="(preset, key) in presetsInnerData">
            <span class="preset-value">{{ preset?.title }}</span>
            <input type="radio"
                   name="preset-radio"
                   @change="presetChecked($event, preset)"
                   :checked="preset.checked"
                   :value="key">
          </label>
        </div>
        <base-button height="30px"
                     width="100%"
                     font-size="15px"
                     border-radius="4px"
                     @click="filterApplyClicked">Apply
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInputCounter from "@/components/Forms/CustomInputCounter";

export default {
  name: "CustomRadioInput",
  components: {
    'custom-input-counter': CustomInputCounter
  },
  props: {
    options: {
      type: Object
    },
    initialData: {
      type: Object,
      required: false,
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    },
    minWidth: {
      type: String
    },
    noPresets: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: 'all',
    },
    customMaxWidth: {
      type: String,
      default: '64px',
    },
    presetsData: {
      type: Object,
      default: () => ({
        all: {
          title: 'All',
        },
        metadata: {
          title: 'Metadata',
        },
        competitors: {
          title: 'Competitors Intelligence',
        },
        rankings: {
          title: 'Rankings',
        },
      })
    },
    openedFromOutside: {
      type: Boolean,
    },
    closeDropdown: {
      type: Number,
      required: false
    },
  },
  emits: ['filter-applied'],
  data() {
    return {
      isOpened: false,
      appliedPreset: {},
      checkedRadio: null,
      presetsInnerData: {},
      filterApplied: false,
      selectedPreset: {
        value: this.value
      },
    }
  },
  methods: {
    inputCounterChanged(value, type) {
      const presetsObject = {...this.selectedPreset};
      presetsObject[type] = value;

      this.selectedPreset = {...presetsObject};
      this.cleanPresets();
    },
    presetChecked(e, preset) {
      for (const key in this.presetsInnerData) {
        this.presetsInnerData[key].checked = key === e?.target?.value;
      }
      this.selectedPreset = {...preset};
    },
    outside() {
      this.isOpened = false;
    },
    filterApplyClicked() {
      this.filterApplied = true;
      this.appliedPreset = {...this.selectedPreset};
      this.$emit('filter-applied', this.selectedPreset);
      this.isOpened = false;
    },
    cleanFilterClicked() {
      this.cleanFilter();
      this.cleanPresets();
      this.$emit('filter-applied', '');
    },
    cleanFilter() {
      setTimeout(() => {
        this.filterApplied = false;
        this.presetsInnerData = {...this.presetsData};
        this.presetChecked('null', {
          value: this.value,
        });

      }, 150);
    },
    cleanPresets() {
      const presetsData = {...this.presetsInnerData};
      for (const key in presetsData) {
        presetsData[key].checked = false;
      }

      this.presetsInnerData = {...presetsData};
    },
    selectDropdownClicked(e) {
      if (e?.target?.classList?.contains('icon') || e?.target?.classList?.contains('applied-icon') || e?.target?.closest('.svg-icons')?.classList?.contains('applied-icon')) {
        if (this.isOpened) {
          this.isOpened = true;
        }
      } else {
        this.isOpened = !this.isOpened;
      }
    }
  },
  mounted() {
    let tempPresetsInnerData = {...this.presetsData};
    this.filterApplied = true;

    if (this.initialData?.value) {
      for (const key in tempPresetsInnerData) {
        if (tempPresetsInnerData[key]?.title === this.initialData?.title) {
          tempPresetsInnerData[key].title = this.initialData.title;
        }


        if (JSON.stringify(this.appliedPreset) === '{}' && tempPresetsInnerData[key]?.title === 'All') {
          tempPresetsInnerData[key].title = 'All';
          tempPresetsInnerData[key].checked = false;
        }

      }
      // this.filterApplied = true;
      this.appliedPreset = {...this.initialData};
    } else {
      for (const key in tempPresetsInnerData) {
        if (this.initialData === null && this.value === 'all' && tempPresetsInnerData[key]?.title === 'All') {
          tempPresetsInnerData[key].title = 'All';
          tempPresetsInnerData[key].checked = true;
          this.appliedPreset.title = 'All Sources'
        }
      }

      if (this.openedFromOutside) {
        this.isOpened = true;
      }
    }

    this.presetsInnerData = {...tempPresetsInnerData};

    if (this.presetsData) {
      this.presetsInnerData = {...this.presetsData};
    }
  },
  computed: {
    minBlockWidth() {
      const customMaxWidth = parseInt(this.customMaxWidth);
      const minWidth = parseInt(this.minWidth);
      if (customMaxWidth > 64) {
        const diff = customMaxWidth - 64;
        return minWidth + diff + 'px';
      }
      return this.minWidth;
    }
  },
  watch: {
    initialData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.cleanFilter();
        this.cleanPresets();
      }
    },
    closeDropdown(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.isOpened = false;
      }
    }
  }
}
</script>

<style scoped src="./styles.scss" lang="scss"></style>