<template>
  <custom-tooltip direction-horizontal="left"
                  direction-vertical="top"
                  :straight="true"
                  width="410px"
                  :with-padding="true"
                  :delayed-closing="true"
                  :style="{padding: 0}"
                  classes="tool-item hover-bg-green">
    <template v-slot:slotTrigger>
      <slot></slot>
    </template>

    <template v-slot:slotContent>
      <div class="coverage-tooltip">
        <div class="definitions-1">
          <div>
            <img src="@/assets/images/icons/app_store_gray.svg"
                 width="16"
                 height="16">
            <span>Current App Store metadata keyword coverage</span>
          </div>
          <div>
            <svg-icon icon="document" />
            <span>New edited metadata keyword coverage</span>
          </div>
        </div>

        <div class="description">
          <div v-if="isKeywordsMode">
            These indicators show <b>how the keyword is covered</b> in the <b>current App Store Metadata</b> and in <b>the Metadata version you’re editing</b>.
          </div>
          <div v-else>
            These indicators show <b>how the word is covered</b> in the <b>current App Store Metadata</b> and in <b>the Metadata version you’re editing</b>.
          </div>
        </div>

        <div class="definitions-2">
          <div class="item" style="font-weight: 600; padding: 0 4px 4px;">
            <div v-if="isKeywordsMode" class="item">
              The keyword is:
            </div>
            <div v-else class="item">
              The word is:
            </div>
          </div>
          <div class="item">
            <div class="meta-coverage-indicator-chart">
              <div class="title full"></div>
            </div>
            <div>
              <b>fully used</b> in the <b>Title</b>
            </div>
          </div>
          <div class="item">
            <div class="meta-coverage-indicator-chart">
              <div class="subtitle full"></div>
            </div>
            <div>
              <b>fully used</b> in the <b>Subtitle</b> and/or <b>Keywords</b> fields
            </div>
          </div>

          <template v-if="isKeywordsMode">
            <div class="item">
              <div class="meta-coverage-indicator-chart">
                <div class="title partial"></div>
                <div class="title"></div>
              </div>
              <div>
                <b>partially used</b> in the <b>Title</b>
              </div>
            </div>
            <div class="item">
              <div class="meta-coverage-indicator-chart">
                <div class="title"></div>
                <div class="subtitle partial"></div>
              </div>
              <div>
                <b>partially used</b> in the <b>Subtitle</b> and/or <b>Keywords</b> fields
              </div>
            </div>

            <div class="item">
              <div class="meta-coverage-indicator-chart">
                <div class="title partial"></div>
                <div class="subtitle partial"></div>
              </div>
              <div>
                <b>partially used</b> in the <b>Title + Subtitle</b> and/or <b>Keywords</b> fields
              </div>
            </div>
          </template>

          <div class="item">
            <div class="meta-coverage-indicator-chart">
              <div class="subtitle"></div>
            </div>
            <div>
              not used
            </div>
          </div>
        </div>
      </div>
    </template>
  </custom-tooltip>
</template>
<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: "CoverageTooltip",
  components: {
    SvgIcon
  },
  props: {
    isKeywordsMode: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
.coverage-tooltip {
  padding: 16px;
  font-size: 14px;

  .definitions-1 {
    div {
      padding: 12px 0;
      border-bottom: 1px solid var(--neutral-200);
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .description {
    padding: 12px 4px 16px;
  }

  .definitions-2 {
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      line-height: 20px;
      padding: 12px 4px;
      border-top: 1px solid var(--neutral-200);
    }
  }
}
</style>