<template>
  <div class="tooltip-block"
       v-click-outside="outside">
    <svg-icon class="close-icon"
              @click="closeIconClicked"
              icon="close"/>
    <div class="heading">Current Meta:</div>
    <div class="text">
      <slot name="tooltip-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableCellTooltip",
  emits: ['close-icon-clicked', 'icon-clicked-outside'],
  methods: {
    closeIconClicked() {
      this.$emit('close-icon-clicked');
    },
    outside() {
    },
  }
}
</script>

<style lang="scss">
.tooltip-block {
  position: absolute;
  z-index: 20;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(160, 190, 221, 0.3);
  border-radius: 4px;
  top: 100%;
  left: 0;
  right: 0;
  transform: translateY(8px);
  padding: 12px;
  cursor: default;

  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #46516D;
    margin-bottom: 4px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #46516D;
    word-wrap: break-word;
  }

  .close-icon {
    font-size: 10px;
    position: absolute;
    top: 12px;
    right: 12px;
    color: #a3b0c5;
    cursor: pointer;
  }
}
</style>